#page-teacher-list {
    width: 100vw;
    height: 100vh;
}

#search-teachers {
    margin-top: 3.2rem;
}

#search-teachers label {
    color: var(--color-text-in-primary);
}

#page-teacher-list main {
    margin:3.2rem auto;
    width: 90%
}


@media (min-width: 700px){
    #page-teacher-list {
        max-width: 100%;
    }

    #search-teachers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 16px;
        position: absolute;
        bottom: -28px;

    }
    
    #search-teachers .input-block + .input-block,
    #search-teachers .input-block + .select-block {
        margin-top: 0;
    }

    #page-teacher-list main {
        padding: 3.2rem 0;
        max-width: 720px;
        margin: 0 auto;
    }
}