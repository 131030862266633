.textarea-block {
    position: relative;
}

.textarea-block + .textarea-block{
    margin-top: 1.4rem;
}

.textarea-block label {
    position: 1.4rem;
}

.textarea-block textarea {
    width: 100%;
    height: 16rem;
    min-height: 8rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    resize: vertical;
    padding: 0 1.6rem;
    font: 1.6rem Archivo;
}

.textarea-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 7px;
}


@media (min-width: 700px){

    .textarea-block + .textarea-block{
        margin-top: 0;
    }

}